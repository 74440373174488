export const LOADER_TEST_ID = 'loader'
export const EMPTY_LIST_TEST_ID = 'empty-view'
export const EMPTY_LIST_ACTION_TEST_ID = 'empty-view-action'
export const ASSET_BROWSE_TEST_ID = 'asset-browse'
export const LIST_CONTAINER_TEST_ID = 'list-container'
export const ERROR_CONTAINER_TEST_ID = 'error-container'
export const PRIVATE_BADGE_TEST_ID = 'private-badge'
export const UPDATED_AT_TEST_ID = 'updated-at'
export const SHARE_LIST_BUTTON_TEST_ID = 'share-list'
export const EDIT_LIST_BUTTON_TEST_ID = 'edit-list'
export const DELETE_LIST_BUTTON_TEST_ID = 'delete-list'
export const COULD_NOT_LOAD_LIST_ACTION_TEST_ID = 'could-not-load-list-action'
export const GO_BACK_BUTTON_TEST_ID = 'go-back'
export const MORE_OPTIONS_DROPDOWN_TEST_ID = 'more-options-dropdown'
